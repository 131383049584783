import {useContext, useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom';
import classNames from 'classnames';

import {SocketContext} from 'services/socket';
import {getItem, setItem} from 'services/storage';

import logo from 'assets/images/logo.png';

function Teams() {
    const navigate = useNavigate();
    const socket = useContext(SocketContext);

    const [player, setPlayer] = useState(() => {
        return getItem('player');
    });

    useEffect(() => {
        if (!player) {
            setItem('player', null);

            return;
        };

        setItem('player', player);
    }, [player]);

    const guests = [
        'Alain Chabat',
        'Anne Depétrini',
        'Laurent Baffie',
        'Marina Foïs',
        'Dominique Farrugia',
        'Daphné Bürki',
        'Édouard Baer',
        'Chantal Lauby',
        'Gérard Darmon',
    ];

    function joinTeam(teamName) {
        setItem('team', teamName);

        navigate(`/buzzer/${teamName}`);
    }

    function getRandomPlaceholder() {
        return guests[Math.floor(Math.random() * guests.length)];
    }

    return (
        <div className="teamsRoot">
            <div className="logo">
                <img src={logo} />
            </div>

            <div className="choice">
                <div className="label">D'abord, tu t'appelles comment ?</div>

                <input type="text" onChange={ (e) => setPlayer(e.target.value) } placeholder={getRandomPlaceholder()} value={player} />

                { player ? (
                    <div className="label">Bienvenue {player} !<br />Tu peux choisir une equipe :</div>
                ) : (
                    <div className="label">Tu preferes rester anonyme ?<br />Alors choisis une equipe :</div>
                ) }

                <div className="teams">
                    <button onClick={() => joinTeam('ketchup')}>
                        <div className={classNames('team', 'ketchup')}>
                            <div className="name">ketchup</div>
                            <div className="letter">k</div>
                        </div>
                    </button>

                    <div className="separator">ou</div>

                    <button onClick={() => joinTeam('mayo')}>
                        <div className={classNames('team', 'mayo')}>
                            <div className="name">mayo</div>
                            <div className="letter">m</div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Teams;
