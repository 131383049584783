import {useState, useEffect, useContext, useRef} from 'react';

import classNames from "classnames/index";

import {SocketContext} from 'services/socket';

import logo from 'assets/images/logo.png';
import qrcode from 'assets/images/qrcode.png';
import scoreImage from 'assets/scores/0.jpg';

import buzzerSound from 'assets/sounds/buzzer.mp3';

import nuggetsJingle from 'assets/jingles/nuggets.mp4';
import selOuPoivreJingle from 'assets/jingles/sel-ou-poivre.mp4';
import menusJingle from 'assets/jingles/menus.mp4';
import lAdditionJingle from 'assets/jingles/l-addition.mp4';
import burgerDeLaMortJingle from 'assets/jingles/burger-de-la-mort.mp4';

function Screen() {
    const socket = useContext(SocketContext);

    const videoRef = useRef(null);

    const [view, setView] = useState('score');

    const [buzzer, setBuzzer] = useState(null);

    const [jingle, setJingle] = useState(null);

    const [score, setScore] = useState({
        ketchup: 0,
        mayo: 0,
    });

    const [scoreImages, setScoreImages] = useState({
        ketchup: scoreImage,
        mayo: scoreImage,
    });

    const buzzerPlayer = new Audio(buzzerSound);

    const jingles = {
        'nuggets': nuggetsJingle,
        'sel-ou-poivre': selOuPoivreJingle,
        'menus': menusJingle,
        'l-addition': lAdditionJingle,
        'burger-de-la-mort': burgerDeLaMortJingle,
    };

    useEffect(() => {
        socket.on('connect', () => {
            getScore();
        });

        socket.on('play-jingle', (name) => {
            if (!isView('score')) return;

            setJingle(name);
        });

        socket.on('buzzing', (data) => {
            if (!isView('score')) return;

            setBuzzer(data);
        });

        socket.on('update-score', (newScore) => {
            setScore(newScore);
        });

        socket.on('set-view', (newView) => {
            setView(newView);
        });

        if (socket.connected) {
            getScore();
        }

        return () => {
            socket.removeAllListeners('play-jingle');
            socket.removeAllListeners('buzzing');
            socket.removeAllListeners('update-score');
        };
    }, [socket, view]);

    useEffect(() => {
        if (!buzzer) return;

        setView('buzzing');
    }, [buzzer]);

    useEffect(() => {
        if (!jingle) return;

        setView('jingle');
    }, [jingle]);

    useEffect(() => {
        switch (view) {
            case 'buzzing':
                buzzerPlayer.play();

                setTimeout(() => {
                    setBuzzer(null);
                    setView('score');
                }, 1300);

                break;
            case 'jingle':
                const videoEl = videoRef.current;

                videoEl.onended = () => {
                    setJingle(null);
                    setView('score');
                };

                videoEl.play().catch(() => {
                    setJingle(null);
                    setView('score');
                });

                break;
        }
    }, [view]);

    useEffect(() => {
        let {ketchup, mayo} = score;

        if (ketchup < 0) ketchup = 0;
        if (ketchup > 25) ketchup = 25;

        if (mayo < 0) mayo = 0;
        if (mayo > 25) mayo = 25;

        Promise.all([
            import(`assets/scores/${ketchup}.jpg`),
            import(`assets/scores/${mayo}.jpg`),
        ]).then(([ketchup, mayo]) => {
            setScoreImages({
                ketchup: ketchup.default,
                mayo: mayo.default,
            });
        });
    }, [score]);

    function getScore() {
        socket.emit('get-score', null, (newScore) => {
            setScore(newScore);
        });
    }

    function isView(viewName) {
        return view === viewName;
    }

    return (
        <div className="screenRoot">
            {isView('jingle') ? (
                <div className="jingle">
                    <div className="ratio ratio-16by9">
                        <video src={jingles[jingle]} ref={videoRef} controls={false} playsInline={true}/>
                    </div>
                </div>
            ) : isView('buzzing') ? (
                <div className={classNames('buzzer', buzzer.teamName)}>
                    <div className={classNames('team', buzzer.teamName)}>
                        <div className="name">{buzzer.teamName}</div>
                        <div className="letter">{buzzer.teamName.charAt(0)}</div>
                    </div>

                    {buzzer.player ? (
                        <div className="player">
                            {buzzer.player}, c'est a toi !
                        </div>
                    ) : (
                        <div className="player">
                            {buzzer.teamName}, c'est a vous !
                        </div>
                    )}
                </div>
            ) : isView('landing') ? (
                <div className="landing">
                    <div className="logo">
                        <img src={logo}/>
                    </div>

                    <div className="content">
                        <div>
                            <div className="title">
                                <div className="welcome">Bienvenue</div>
                                Scannes le QRCode pour choisir une equipe !
                            </div>

                            <div className="qrcode">
                                <img src={qrcode}/>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="score">
                    <div className="side">
                        <div className={classNames('value', 'ketchup')}>{score.ketchup}</div>

                        <div className="image">
                            <img src={scoreImages.ketchup}/>
                        </div>
                    </div>

                    <div className="side">
                        <div className={classNames('value', 'mayo')}>{score.mayo}</div>

                        <div className="image">
                            <img src={scoreImages.mayo}/>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Screen;
