import {useState, useEffect, useContext} from 'react';
import classNames from 'classnames';

import {SocketContext} from 'services/socket';

function Remote() {
    const socket = useContext(SocketContext);

    function addScore(increment, teamName) {
        socket.emit((increment > 0) ? 'increment-score' : 'decrement-score', teamName);
    }

    function resetScore() {
        if (confirm('Sûr ?')) {
            socket.emit('reset-score');
        }
    }

    function lockBuzzers() {
        socket.emit('lock-buzzers');
    }

    function unlockBuzzers() {
        socket.emit('unlock-buzzers');
    }

    function playJingle(jingle) {
        socket.emit('play-jingle', jingle);
    }

    function setView(view) {
        socket.emit('set-view', view);
    }

    return (
        <div className="remoteRoot">
            <div className="title">Gestion du jeu</div>

            <div className="sections">
                <div>
                    <div className="subtitle">Score</div>
                    <div className="score">
                        <div className="actions">
                            <div className="action">
                                <button className={classNames('multiline', 'ketchup')}
                                        onClick={() => addScore(1, 'ketchup')}>
                                    <div className="increment">+1</div>
                                    <div className="name">ketchup</div>
                                </button>
                            </div>

                            <div className="action">
                                <button className={classNames('multiline', 'mayo')} onClick={() => addScore(1, 'mayo')}>
                                    <div className="increment">+1</div>
                                    <div className="name">mayo</div>
                                </button>
                            </div>

                            <div className="action">
                                <button className={classNames('multiline', 'ketchup')}
                                        onClick={() => addScore(-1, 'ketchup')}>
                                    <div className="increment">-1</div>
                                    <div className="name">ketchup</div>
                                </button>
                            </div>

                            <div className="action">
                                <button className={classNames('multiline', 'mayo')} onClick={() => addScore(-1, 'mayo')}>
                                    <div className="increment">-1</div>
                                    <div className="name">mayo</div>
                                </button>
                            </div>

                            <div className="action">
                                <button onClick={() => resetScore()}>
                                    RaZ des points
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="subtitle">Buzzers</div>
                    <div className="buzzers">
                        <div className="actions">
                            <div className="action">
                                <button onClick={() => lockBuzzers()}>Bloquer</button>
                            </div>

                            <div className="action">
                                <button onClick={() => unlockBuzzers()}>Debloquer</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="subtitle">Jingles</div>
                    <div className="jingles">
                        <div className="actions">
                            <div className="action">
                                <button onClick={() => playJingle('nuggets')}>Nuggets</button>
                            </div>
                            <div className="action">
                                <button onClick={() => playJingle('sel-ou-poivre')}>Sel ou poivre</button>
                            </div>
                            <div className="action">
                                <button onClick={() => playJingle('menus')}>Menus</button>
                            </div>
                            <div className="action">
                                <button onClick={() => playJingle('l-addition')}>L'addition</button>
                            </div>
                            <div className="action">
                                <button onClick={() => playJingle('burger-de-la-mort')}>Burger de la mort</button>
                            </div>
                        </div>
                    </div>

                    <div className="subtitle">Ecran principal</div>
                    <div className="jingles">
                        <div className="actions">
                            <div className="action">
                                <button onClick={() => setView('score')}>Scores</button>
                            </div>
                            <div className="action">
                                <button onClick={() => setView('landing')}>QRCode</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Remote;
