import {useEffect, useState, useContext} from "react";
import {useParams, useNavigate} from 'react-router-dom';
import classNames from 'classnames';

import {SocketContext} from 'services/socket';
import {getItem, setItem} from 'services/storage';

import burgerImg from 'assets/images/burger.png';

function Buzzer() {
    const socket = useContext(SocketContext);
    const navigate = useNavigate();
    const { teamName } = useParams();

    const [locked, setLocked] = useState(true);
    const [score, setScore] = useState(0);
    const [playerWithTeam] = useState(() => {
        return {
            player: getItem('player'),
            team: getItem('team'),
        };
    });

    useEffect(() => {
        socket.on('connect', () => {
            initialize();
            joinTeam();
        });

        socket.on('lock-buzzers', () => {
            setLocked(true);
        });

        socket.on('unlock-buzzers', () => {
            setLocked(false);
        });

        socket.on(`update-score-${teamName}`, (newScore) => {
            setScore(newScore);
        });

        if (socket.connected) {
            initialize();
            joinTeam();
        }

        return () => {
            socket.removeAllListeners(`update-score-${teamName}`);
            socket.removeAllListeners('lock-buzzers');
            socket.removeAllListeners('unlock-buzzers');
        };
    }, [socket]);

    function initialize() {
        socket.emit('get-score', teamName, (newScore) => {
            setScore(newScore);
        });

        socket.emit('get-buzzers-state', ({ locked }) => {
            setLocked(locked);
        });
    }

    function joinTeam() {
        let { team } = playerWithTeam;

        if (!team) {
            team = teamName;
            setItem('team', teamName);
        }

        socket.emit('join-team', {
            player: playerWithTeam.player,
            team: team,
        });
    }

    function onClickHandler() {
        socket.emit('buzzer', teamName);
    }

    return (
        <div className={classNames('buzzerRoot', teamName )}>
            <div className="meta">
                <div className={classNames('team', teamName)}>
                    <div className="name">{teamName}</div>
                    <div className="letter">{teamName.charAt(0)}</div>
                </div>

                <div className="score">
                    Score: <span className="value">{score}</span>
                </div>
            </div>

            <button className="buzzer" onClick={() => onClickHandler()} disabled={locked}>
                <img src={burgerImg}/>
            </button>
        </div>
    );
}

export default Buzzer;
