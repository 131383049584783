export function setItem(key, value) {
    if (!value) value = null;

    localStorage.setItem(`bquiz.${key}`, JSON.stringify(value));
}

export function getItem(key, defaultValue = null) {
    let value = localStorage.getItem(`bquiz.${key}`);

    if (!value && defaultValue) return defaultValue;

    return JSON.parse(value);
}
