import React, {useEffect} from 'react';
import {Routes, Route} from "react-router-dom";

import {socket, SocketContext} from 'services/socket';

import Teams from 'pages/teams';
import Buzzer from 'pages/buzzer';
import Screen from 'pages/screen';
import Remote from 'pages/remote';

import 'app.scss';

function App() {
    useEffect(() => {
        return () => {
            if (socket.connected) {
                socket.disconnect();
            }
        };
    }, [socket]);

    return (
        <SocketContext.Provider value={socket}>
            <Routes>
                <Route path="/">
                    <Route index element={<Teams/>}/>
                    <Route path="buzzer/:teamName" element={<Buzzer/>}/>
                    <Route path="screen" element={<Screen/>}/>
                    <Route path="remote" element={<Remote/>}/>
                    <Route path="*" element={<Teams/>}/>
                </Route>
            </Routes>
        </SocketContext.Provider>
    );
}

export default App;
